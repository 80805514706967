<template>
    <div class="point" @click="showPoint(pointId)">
        <p class="title-address" style="font-size:17px">{{ point.address }}</p>
        <div class="additional-options" style="font-size: 13px;">
          <span style="color: #828282">
            {{ getDeliveryCostLabel(point) }}
              <span v-if="point.payLimitsValue">
                  <span v-if="point.payLimitsValue.limited">•
                    <span v-for="(payLimit , i) in point.payLimitsValue.texts" :key="i">
                      <span> {{ payLimit.text }}</span>
                    </span>
                  </span>
              </span>
          </span>
            <p style="color:#828282"
               v-if="point.serviceDescriptionValue && point.serviceDescriptionValue.no !== ''">
                {{ point.serviceDescriptionValue.no }}</p>
        </div>
    </div>
</template>

<script>
import Points from '../helpers/Points'
import DeliveryCostLabel from "../helpers/delivery-cost-labels/DeliveryCostLabel";
import ContainerPointTemplate from "../helpers/delivery-cost-labels/templates/ContainerPoint.tpl";

export default {
    name: 'container-point',
    props: [
        'pointId'
    ],
    computed: {
        point() {
            return Points.getRestructurePoint(this.pointId);
        }
    },
    methods: {

        showPoint(pointId) {
            this.$root.$emit('get:point', pointId);
        },

        getCarrier(point) {
            if (point.carrierValue === 'FivePost') {
                return '5post';
            } else if (point.carrierValue === 'Store') {
                return  'Магазин';
            }

            return point.carrierValue;
        },

        getDeliveryCostLabel(point) {
          return new DeliveryCostLabel(
              ContainerPointTemplate,
              point.transit,
              point.cost,
              this.getCarrier(point),
              this.getPaymentMode(point)
          ).make();
        },

        getPaymentMode(point) {
          return Points.getPaymentsLimits(point.limits_payment_mode);
        }
    }
}
</script>
