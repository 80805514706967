export default {
    'true,true': '',
    'true,false': '%icon% Стоимость: %deliveryCost%',
    'false,true': '%icon% Доставим за %transit%',
    'false,false': '%icon% Доставим за %transit%, %deliveryCost%',
    'icon': {
        'template': '<i class="icon delivery"></i>'
    },
    'deliveryCost': {
        'template': '<span class="cost">%value%₽</span>'
    }
};