export default {
    'true,true': '%carrier% • %paymentMode%',
    'true,false': '%carrier% • %paymentMode% • Стоимость: %deliveryCost%',
    'false,true': '%carrier% • %paymentMode% • Доставим за %transit%',
    'false,false': '%carrier% • %paymentMode% • Доставка: %transit% • %deliveryCost%',
    'icon': {
        'template': ''
    },
    'deliveryCost': {
        'template': '%value%₽'
    }
};