export default {
    'true,true': '',
    'true,false': '%icon% Стоимость: %deliveryCost%',
    'false,true': '%icon% Доставим за %transit%',
    'false,false': '%icon% Доставим за %transit%, %deliveryCost%',
    'icon': {
        'template': '<i class="icon delivery" style="margin: 0 0 0 8px"></i>'
    },
    'deliveryCost': {
        'template': '<span style="color:#337AB7"><b>%value%₽</b></span>'
    }
};