import Vue from 'vue'

import General from './components/container-general.vue';
import store from './store'

import vSelect from 'vue-select'
import App from './App.vue'

import './assets/sass/app.scss'

// Vue.config.devtool = 'source-map' // удобно для отладки
Vue.config.productionTip = false // отключаем вывод в консоль предупреждений vue
Vue.component("v-select", vSelect);
new Vue({
  el:'#app',
  components: {App, General},
  store,
  template: '<App/>',
  render: h => h(App),
});
