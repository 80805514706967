<template>
  <div class="filter-button" :class="{showed: isActive}">
    <i class="icon" :class="service.icon"></i>
    {{ service.label }}
    <div class="info-box" @click="toggleTooltip(service['key'], $event)">
      <i class="icon" :class="getBtnClass(service['key'])"></i>
    </div>
    <div class="tooltip">
      <div class="tooltip-yes" v-show="service['yes'].length">
        <i class="icon check"></i>
        {{ service['yes'] }}
      </div>
      <div class="tooltip-no" v-show="service['no']">
        <i class="icon not_available"></i>
        {{ service['no'] }}
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapMutations} from 'vuex';

export default {
  name: "service-filter-button",
  props: ['service'],
  computed: {
    ...mapGetters(['getStateTooltipTypes']),
    isActive() {
      return this.$store.state.tooltipTypes[this.service.key];
    }
  },
  methods: {
    ...mapMutations(['setActiveTooltipState']),

    toggleTooltip(serviceType, event) {
      event.preventDefault();

      if (window.innerWidth <= 1024) {
        this.setActiveTooltipState(serviceType);
      }
    },

    getBtnClass(serviceType) {
      const activeTooltips = this.getStateTooltipTypes;
      return activeTooltips[serviceType] ? 'close' : 'info';
    }
  }
}
</script>
