<template>
  <div class="container-right col-4"
       :class="{'block' : !this.$parent.toogleToolbar || this.getCurrentState === 'InformationPoint', 'none': this.$parent.toogleToolbar}">
    <div class="loader" style="opacity: 50%" v-if="this.getRightPanelLoader">
      <half-circle-spinner
          :animation-duration="1000"
          :size="60"
          color="#337ab7"
      />
    </div>
    <keep-alive>
      <component :is="currentTabComponent()" v-bind=currentProperties()></component>
    </keep-alive>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import {HalfCircleSpinner} from 'epic-spinners';

import ContainerFilterPage from './container-filter-page';
import ContainerInformationPoint from './container-information-point'
import ContainerPointsList from './container-points-list';
import ContainerPoint from './container-point';
import Points from '../helpers/Points';
import MapHelpers from '../helpers/Map';


export default {
  name: 'container-right',
  components: {
    ContainerInformationPoint,
    ContainerPointsList,
    ContainerPoint,
    ContainerFilterPage,
    HalfCircleSpinner
  },
  props: ['visiblePoints', 'points'],
  data() {
    return {
      detailPoint: null,
      component: '',
    }
  },
  computed: {
    ...mapGetters(['getCurrentState', 'getIsFilterActive', 'getRightPanelLoader']),
  },

  mounted() {

    let t = this;
    t.pointsById = {};
    this.component = this.currentTabComponent();

    this.$root.$on('back', (component) => {
      if (!component) {
        this.setRightPanelState('PointsList');
      } else {
        this.setRightPanelState(component);
      }

      MapHelpers.getInstance().setDefaultOptionsInPlacemark();
    });

        // show point info by click on point list item or on map marker
        this.$root.$on('get:point', (pointId, isMap) => {
            let zoomRate = 16;
            let point = Points.getRestructurePoint(pointId);
            let duration = 500;

            // window.MapYandex = MapHelpers.getInstance().MapYandex;
            MapHelpers.getInstance().MapYandex.panTo([[point.latitude, point.longitude]], {duration: duration});

            if (MapHelpers.getInstance().MapYandex.getZoom() < zoomRate) {
                setTimeout(() => {
                    MapHelpers.getInstance().MapYandex.setCenter([point.latitude, point.longitude])
                    MapHelpers.getInstance().MapYandex.setZoom(zoomRate, {duration: duration})
                }, duration + 500);
            }

      this.setRightPanelLoader(true);
      if (isMap) {
        this.$parent.toogleToolbarMapList(false);
      }

      t.getPointById(pointId).then((structuredPoint) => {
        this.detailPoint = structuredPoint;
        this.detailPoint.isMap = isMap || false;
        this.setRightPanelState('InformationPoint');
        this.setRightPanelLoader(false);

        MapHelpers.getInstance().handleClickByPlacemark(pointId);
      }).catch(() => {
        alert('Произошла ошибка при загрузке информации о пункте выдачи. Попробуйте выбрать пункт ещё раз.')
        this.setRightPanelLoader(false);
      });
    })

  },
  methods: {
    ...mapMutations(['setRightPanelState', 'setRightPanelLoader']),
    ...mapGetters(['getPrevState']),

    currentTabComponent: function () {
      return 'Container' + this.getCurrentState;
    },
    currentProperties: function () {

      if (this.currentTabComponent() === 'ContainerInformationPoint') {
        return {'point': this.detailPoint}
      }

      if (this.currentTabComponent() === 'ContainerPointsList') {

        return {
          'visiblePoints': this.visiblePoints,
        };
      }

      return {'default': '[]'};
    },

    getPointById(pointId) {

      return new Promise((resolve, reject) => {
        let t = this;
        let structuredPoint = null;

        if (t.pointsById.hasOwnProperty(pointId)) {
          resolve(t.pointsById[pointId]);
          return;
        }

        Points.getFullJsonForShop([pointId]).then(() => {

          structuredPoint = Points.getRestructurePointFull(pointId);
          if (!structuredPoint) {
            reject(new Error('Point not found'));
            return;
          }
          t.pointsById[pointId] = structuredPoint;
          resolve(structuredPoint);
        })
      })

    },

  },
}
</script>
