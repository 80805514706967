export default class ServicesDescriptionMapper {
    static map(description) {
        return {
            TRY_ON: {
                yes: description[2].yes,
                no: description[2].no,
                icon: 'clothes-hanger',
                label: 'Примерка верхней одежды',
                key: 'TRY_ON'
            },
            PARTIAL_RETURN: {
                yes: description[1].yes,
                no: description[1].no,
                icon: 'partial',
                label: 'Частичный выкуп',
                key: 'PARTIAL_RETURN'
            },
            TRY_ON_SHOES_AND_OUTERWEAR: {
                yes: description[3].yes,
                no: description[3].no,
                icon: 'trench-coat',
                label: 'Примерочная',
                key: 'TRY_ON_SHOES_AND_OUTERWEAR'
            }
        };
    }
}