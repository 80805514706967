<template>
  <General/>
</template>

<script>
  import General from './components/container-general.vue'

  export default {
    name: 'App',
    components: {
      General
    }
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
</style>
