import axios from 'axios';

/**
 * Обязательно привязать bindom this
 */

export default {

    geocode: function (request, options) {

        var deferred = this.ymaps.vow.defer(),
            geoObjects = new this.ymaps.GeoObjectCollection();

        if (options == null || Object.keys(options) == 'provider') {
            return;
        }

        let geocoderUrl = process.env.VUE_APP_GEOCODER_URL || '';
        axios.post(geocoderUrl + '/geocode',

            JSON.stringify({
                request: request,
                options: options
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
            let data = response.data;
            for (var i = 0; i < data.geoObjects.length; i++) {
                geoObjects.add(new this.ymaps.GeoObject({
                    geometry: data.geoObjects[i].geometry,
                    properties: {
                        metaDataProperty: data.geoObjects[i].properties.metaDataProperty,
                        name: data.geoObjects[i].properties.name,
                        description: data.geoObjects[i].properties.description,
                        text: data.geoObjects[i].properties.description,
                        boundedBy: data.geoObjects[i].bbox,
                        hintContent: data.geoObjects[i].properties.name,
                        balloonContentHeader: data.geoObjects[i].properties.name,
                        balloonContentBody: data.geoObjects[i].properties.description,
                    }
                }));
            }
            deferred.resolve({
                "geoObjects": geoObjects,
                "metaData": data.metaData || {}
            });
        }).catch((data) => {
            deferred.reject(data);
        })
        return deferred.promise();
    },

    suggest: function (request, options) {
        options.provider = 'yandex#map';
        return this.ymaps.suggest(request, options);
    }
}

