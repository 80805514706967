<template>
  <div class="container-points-list" ref="pointsList">
    <div class="filters-block">
      <h1 class="title-container">Выберите пункт выдачи</h1>
      <ContainerSearch></ContainerSearch>
      <div class="filter-button-wrapper">
        <div class="filter-button" v-if="getFilterSettings.disableFilters" @click="() => {
            this.$parent.$parent.toogleToolbarMapList(false);
            this.$store.commit('setRightPanelState', 'FilterPage');
        }">
          <i class="icon filter-white"></i>Фильтры <i class="icon arrow-white"
                                                      :class="{'active': getIsFilterActive}"></i>
        </div>
      </div>

      <div class="selected-filters"
           :style="getIsFilterActive === false ? 'visibility:visible' :'visibility:hidden; position: absolute; top: -100%;'">
        <ContainerActiveFilter></ContainerActiveFilter>
      </div>
    </div>
    <div class="container-points-list-items"
         v-if="showedPointIds">
      <ContainerPoint v-for="(pointId, i) in showedPointIds" :key="i" :pointId="pointId" :index="(i+1)"/>
      <slot v-if="showedPointIds.length > 0">
        <div class="button-container" v-show="chunkLength() > itteral+1">
          <div class="button blue vertical-center" style="width:100%" id="showNext" @click="showNext"
               :data-itteral="itteral">Показать еще
          </div>
        </div>
        <div class="button-container" v-show="chunkLength() <= itteral+1">
          <div class="button vertical-center disabled" style="width:100%">Показаны все результаты</div>
        </div>
      </slot>
      <slot v-else>
        <div class="button-container" style="height: fit-content; margin-top: 30px">
          <div class="button vertical-center disabled"
               style="width:100%; color: #cdcdcd;border: 1px solid #cdcdcd;height: fit-content">Ничего не
            нашли, попробуйте изменить масштаб карты или задать другие фильтры
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import {chunk} from 'lodash';
import {mapGetters} from 'vuex';

import ContainerSearch from './container-search';
import ContainerActiveFilter from './container-active-filter'
import ContainerPoint from './container-point';


export default {
  name: 'container-points-list',
  components: {
    ContainerSearch,
    ContainerPoint,
    ContainerActiveFilter
  },
  data() {
    return {
      showedPointIds: [],
      itteral: 0,
    }
  },
  computed: {
    ...mapGetters(['getActiveFilters', 'getIsFilterActive', 'getVisiblePointIds', 'getFilterSettings']),

    chunkPoints() {
      let chunkSize = 10;
      let arPointIds = this.getVisiblePointIds.length > 0 ? this.getVisiblePointIds : [];
      return chunk(arPointIds, chunkSize);
    }
  },
  watch: {
    getVisiblePointIds: function () {
      this.itteral = 0;
      this.showNext()
      return this.chunkPoints;
    },
  },
  mounted() {
    this.showNext();
  },
  methods: {
    chunkLength() {
      return this.chunkPoints.length;
    },

    showNext: function (event) {
      if (!event) {
        this.itteral = 0;
        this.showedPointIds = [];
        this.$refs.pointsList.scrollTop = 0;
      } else {
        ++this.itteral
      }

      let points = this.chunkPoints[parseInt(this.itteral)] || [];
      this.showedPointIds.push(...points);
    },
  },
}
</script>
