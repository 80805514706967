<template>
    <div v-if="getActiveFilters.length !== 0">
        <label v-if="getActiveFilters.types.POSTAMATS">
            <input type="checkbox" style="display: none" @click="setFilterParam('types', 'POSTAMATS', false)">
            <div class="filter-button">Постамат</div>
        </label>
        <label v-if="getActiveFilters.types.PICKUP_POINTS">
            <input type="checkbox" style="display: none" @click="setFilterParam('types', 'PICKUP_POINTS', false)">
            <div class="filter-button">Пункт выдачи</div>
        </label>
        <label v-if="getActiveFilters.types.STORES">
          <input type="checkbox" style="display: none" @click="setFilterParam('types', 'STORES', false)">
          <div class="filter-button">Магазин</div>
        </label>
        <label v-if="getActiveFilters.services.PARTIAL_RETURN">
            <input type="checkbox" style="display: none" @click="setFilterParam('services', 'PARTIAL_RETURN', false)">
            <div class="filter-button">Частичный выкуп</div>
        </label>
        <label v-if="getActiveFilters.services.TRY_ON">
            <input type="checkbox" style="display: none" @click="setFilterParam('services', 'TRY_ON', false)">
            <div class="filter-button">Примерочная</div>
        </label>
        <label v-if="getActiveFilters.services.TRY_ON_SHOES_AND_OUTERWEAR">
            <input type="checkbox" style="display: none" @click="setFilterParam('services', 'TRY_ON_SHOES_AND_OUTERWEAR', false)">
            <div class="filter-button">Примерка верхней одежды</div>
        </label>
        <label v-if="getActiveFilters.carriers.Hermes">
            <input type="checkbox" style="display: none" @click="setFilterParam('carriers', 'Hermes', false)">
            <div class="filter-button">Hermes</div>
        </label>
        <label v-if="getActiveFilters.carriers.PickPoint">
            <input type="checkbox" style="display: none" @click="setFilterParam('carriers', 'PickPoint', false)">
            <div class="filter-button">PickPoint</div>
        </label>
        <label v-if="getActiveFilters.carriers.Boxberry">
            <input type="checkbox" style="display: none" @click="setFilterParam('carriers', 'Boxberry', false)">
            <div class="filter-button">BoxBerry</div>
        </label>
        <label v-if="getActiveFilters.carriers.FivePost">
            <input type="checkbox" style="display: none" @click="setFilterParam('carriers', 'FivePost', false)">
            <div class="filter-button">5post</div>
        </label>
        <label v-if="getActiveFilters.carriers.Store">
          <input type="checkbox" style="display: none" @click="setFilterParam('carriers', 'Store', false)">
          <div class="filter-button">Доставка в магазин</div>
        </label>
        <label v-if="getActiveFilters.paymentModes.CASH">
          <input type="checkbox" style="display: none" @click="setFilterParam('paymentModes', 'CASH', false)">
          <div class="filter-button">Наличными</div>
        </label>
        <label v-if="getActiveFilters.paymentModes.CASH_AND_BANK_CARD">
          <input type="checkbox" style="display: none" @click="setFilterParam('paymentModes', 'CASH_AND_BANK_CARD', false)">
          <div class="filter-button">Наличными и банковской картой</div>
        </label>
        <label v-if="getActiveFilters.paymentModes.BANK_CARD">
          <input type="checkbox" style="display: none" @click="setFilterParam('paymentModes', 'BANK_CARD', false)">
          <div class="filter-button">банковской картой</div>
        </label>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
    name: 'container-active-filter',
    data() {
        return {}
    },
    computed: {...mapGetters(['getActiveFilters'])},
    methods: {
        ...mapMutations(['setActiveFilters', 'setMapLoader', 'setRightPanelLoader']),

        setFilterParam(section, param, value) {
            let newActiveFilter = JSON.parse(JSON.stringify(this.getActiveFilters));
            newActiveFilter[section][param] = value;
            this.setActiveFilters(newActiveFilter);
        }
    },
}
</script>
