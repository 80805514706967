<template>
    <div class="container-filter">
        <v-select
                @search="searchInput"
                @input="setSearch"
                v-model="search"
                placeholder="Город, улица, метро..."
                :options="resultSearchYandex"
                :filterable="false"
                :disabled="!getIsMapInit">
            <template #no-options="{ search, searching }">

                <span v-if="search.length === 0">Начните вводить</span>
                <span v-else-if="search.length > 0 && searching === true">К сожалению, не нашли</span>
                <span v-else-if="searching === false">Поиск</span>

            </template>
        </v-select>
    </div>
</template>

<script>

import {debounce, map} from 'lodash';
import 'vue-select/dist/vue-select.css';
import MapHelpers from '../helpers/Map';
import {mapGetters} from 'vuex';

export default {

    name: 'container-search',
    data() {
        return {
            mapLoaded: false,
            search: '',
            resultSearchYandex: [],
        }
    },
    created() {
        this.searchControl = false;
    },
    watch: {
        getIsMapInit(val) {
            if (this.mapLoaded || !val) {
                return;
            }

            //var searchControl = MapHelpers.getInstance().MapYandex.controls.get('searchControl');
            //searchControl.options.set('provider', MapHelpers.getInstance().ProxyGeocoder);
            this.searchControl = new (MapHelpers.getInstance().ymaps.control.SearchControl);
            this.mapLoaded = true;
        }
    },
    computed: {
        ...mapGetters(['getIsMapInit'])
    },
    methods: {
        setSearch(searchString) {

            if (searchString == null) {
                return;
            }

            this.search = searchString.label;
            this.searchControl.search(this.search, {
                results: 1,
                //provider: MapHelpers.getInstance().ProxyGeocoder
            }).then(() => {

                var geoObjectsArray = this.searchControl.getResultsArray();
                if (geoObjectsArray.length) {

                    if (typeof MapHelpers.getInstance().searchPoint == "object") {
                        MapHelpers.getInstance().MapYandex.geoObjects.remove(MapHelpers.getInstance().searchPoint);
                    }

                    MapHelpers.getInstance().searchPoint = geoObjectsArray[0];
                    MapHelpers.getInstance().MapYandex.geoObjects.add(MapHelpers.getInstance().searchPoint);
                    MapHelpers.getInstance().MapYandex.setBounds(MapHelpers.getInstance().searchPoint.properties.get('boundedBy'));
                }

            })
        },

        searchInput: debounce(function (search, loading) {
            if (search.length > 1) {

                // если уберешь эту строку, то не сможешь выбрать в селекте НИЧЕГО
                if (!search) {
                    return;
                }

                loading(true);

                this.resultSearchYandex = [];
                this.search = search;
                let ymaps = MapHelpers.getInstance().ymaps;
                ymaps.suggest(search).then((suggestItems) => {

                    if (suggestItems.length) {
                        let names = {};
                        let i = 0;

                        map(suggestItems, (val) => {
                            if (!names.hasOwnProperty(val.displayName)) {
                                this.resultSearchYandex.push({
                                    code: i++,
                                    label: val.displayName,
                                })

                                names[val.displayName] = true;
                            }
                        });
                    }

                    loading(false)

                }, () => loading(false))
            }
        }, 500)
    },
}
</script>
