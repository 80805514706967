import Utils from "../Utils";

export default class DeliveryCostLabel {
    template = {};

    labelKeysMap = [];

    constructor(template, transit, deliveryCost, carrier = '', paymentMode = '') {
        this.template = template;

        this.labelKeysMap.push({'key': 'icon', 'value': this.template.icon.template});
        this.labelKeysMap.push({'key': 'transit', 'value': transit});
        this.labelKeysMap.push({
            'key': 'deliveryCost',
            'value': this.template.deliveryCost.template.replace('%value%', deliveryCost)
        });
        this.labelKeysMap.push({'key': 'carrier', 'value': carrier});
        this.labelKeysMap.push({'key': 'paymentMode', 'value': paymentMode});
    }

    /**
     * @returns {string}
     */
    make() {
        return this.labelKeysMap.reduce(
            (str, el) => str.replace(`%${el.key}%`, el.value), this.template[this.getKey()]
        );
    }

    /**
     * @returns {string}
     */
    getKey() {
        const disableDeliveryTime = Utils.getUrlParameter('disableDeliveryTime') || true;
        const disableCarrierFee = Utils.getUrlParameter('disableCarrierFee') || true;

        return `${disableDeliveryTime},${disableCarrierFee}`;
    }
}