export default class Utils {
    static getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] == sParam) {
                return sParameterName[1];
            }
        }
    }

    static isAnyTrue(obj) {
        const values = Object.values(obj);
        return values.some(function(val) {
            return val === true;
        });
    }
}
