import {filter} from 'lodash'
import axios from 'axios';
import Utils from "./Utils";
import Filters from "./Filters";
import PaymentModesMapper from "./PaymentModesMapper";

export default class PointsStatic {
    // save all points here for disable reactivity for memory economy
    static pointsResult = {
        carriersMap: {},
        pointFields: [],
        points: {},
        servicesDescription: {},
    }
    static pointsResultFull = {
        carriersMap: {},
        pointFields: [],
        points: {},
        servicesDescription: {},
    }
    // static Points = null;
    static pointTypesNames = JSON.parse(JSON.stringify(Filters.TYPES_NAMES));

    static visibleCarriers = JSON.parse(JSON.stringify(Filters.FILTER_SETTINGS.carriers));

    static visiblePointTypes = JSON.parse(JSON.stringify(Filters.FILTER_SETTINGS.types));

    static visiblePointServices = JSON.parse(JSON.stringify(Filters.FILTER_SETTINGS.services));

    static requestPoints(filterSettings) {
        return new Promise((resolve) => {
            if (!filterSettings) {
                return false;
            }

            this.getJsonForShop(filterSettings).then((filteredResult) => {
                resolve(filteredResult)
            });
        })
    }

    static getJsonForShop(filterSettings) {

        let shopId = parseInt(Utils.getUrlParameter('shop')) || process.env.VUE_APP_SHOP_ID;
        let filter = this.convertSettingsToFilter(filterSettings);

        return new Promise((resolve) => {

            if (Object.keys(PointsStatic.pointsResult.points).length === 0 && Utils.isAnyTrue(filterSettings.types)) {
                let apiUrl = process.env.VUE_APP_BACKEND_URL || '';
                let sendData = {};
                if (filter && Object.keys(filter).length > 0) {
                    Object.assign(sendData, filter);
                }

                axios({
                    method: 'get',
                    url: apiUrl + '/points/' + shopId,
                    params: sendData,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then((response) => {

                    let carriersMap = response.data.carriersMap;
                    let carrierIndex = response.data.pointFields.indexOf('carrier');
                    let pointType = response.data.pointFields.indexOf('point_type');
                    let tryOnAll = response.data.pointFields.indexOf('try_on_all');
                    let tryOnPartial = response.data.pointFields.indexOf('try_on_partial');
                    let partialReturn = response.data.pointFields.indexOf('partial_return');

                    for (let pointId in response.data.points) {
                        let point = response.data.points[pointId];
                        let carrierName = carriersMap[point[carrierIndex]];
                        let pointTypeName = this.pointTypesNames[point[pointType]];

                        this.visibleCarriers[carrierName] = true;
                        this.visiblePointTypes[pointTypeName] = true;

                        if(point[tryOnAll]) {
                            this.visiblePointServices.TRY_ON = true;
                        }

                        if(point[tryOnPartial]) {
                            this.visiblePointServices.TRY_ON_SHOES_AND_OUTERWEAR = true;
                        }

                        if(point[partialReturn]) {
                            this.visiblePointServices.PARTIAL_RETURN = true;
                        }
                    }
                    PointsStatic.pointsResult = response.data;

                    resolve(response.data);
                }).catch((error) => {
                    console.error(error);
                    resolve({})
                })
            } else {
                //CACHE
                resolve(PointsStatic.pointsResult);
            }
        });
    }

    static getFullJsonForShop(pointIds) {

        let shopId = parseInt(Utils.getUrlParameter('shop')) || process.env.VUE_APP_SHOP_ID;
        let t = this;

        return new Promise((resolve) => {
            if (!pointIds || pointIds.length > 300) {
                resolve({});
                return;
            }

            let apiUrl = process.env.VUE_APP_BACKEND_URL || '';
            let sendData = {
                pointIds: pointIds,
                structure: 'full',
            };

            axios({
                method: 'get',
                url: apiUrl + '/points/' + shopId,
                params: sendData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {

                if (Object.keys(t.pointsResultFull.points).length === 0) {
                    t.pointsResultFull = response.data;
                } else {
                    t.pointsResultFull.points = Object.assign(t.pointsResultFull.points, response.data.points);
                }

                resolve(response.data);
            }).catch((error) => {
                console.error(error);
                resolve({})
            })
        });
    }

    static restructurePoint(point, pointsResult, pointId) {
        let newPoint = {};
        pointsResult.pointFields.forEach(function (field, index) {
            newPoint[field] = point[index];
        });

        newPoint.id = pointId;
        newPoint.serviceDescriptionValue = this.getServiceDescription(point, pointsResult.pointFields, pointsResult.servicesDescription);
        newPoint.carrierValue = this.getCarrier(point, pointsResult.pointFields, pointsResult.carriersMap);
        newPoint.payLimitsValue = this.getPaymentsLimits(newPoint.limits_payment_mode);
        newPoint.transit = this.getTransit(point, pointsResult.pointFields);

        newPoint.cost = Utils.getUrlParameter('deliveryCost') || newPoint.cost;
        newPoint.disableDeliveryTime = Utils.getUrlParameter('disableDeliveryTime') || true;

        if (newPoint.hasOwnProperty('stations')) {
            newPoint.nearestMetro = filter(newPoint.stations, (val) => val !== '').length > 0;
        }

        return newPoint
    }

    static getRestructurePoint(pointId) {

        if (!this.pointsResult || !this.pointsResult.hasOwnProperty('points') || !this.pointsResult.points.hasOwnProperty(pointId)) {
            return null;
        }

        let point = this.pointsResult.points[pointId];

        return this.restructurePoint(point, this.pointsResult, pointId)
    }

    static getRestructurePointFull(pointId) {
        if (!this.pointsResultFull || !this.pointsResultFull.hasOwnProperty('points') || !this.pointsResultFull.points.hasOwnProperty(pointId)) {
            return null;
        }

        let point = this.pointsResultFull.points[pointId];
        return this.restructurePoint(point, this.pointsResultFull, pointId);
    }

    static getVisibleCarriers() {
        return this.visibleCarriers;
    }

    static getVisibleServices() {
        return this.visiblePointServices;
    }

    static getVisibleTypes() {
        return this.visiblePointTypes;
    }

    static convertSettingsToFilter(settings) {

        let filter = {
            'point_type': [],
            'carriers': [],
            'payment_modes': []
        };

        //look for enabled filters in settings
        for (const typeName in settings.types) {
            if(settings.types[typeName]) {
                const typeId = Object.keys(Filters.TYPES_NAMES).find(
                    key => Filters.TYPES_NAMES[key] === typeName
                );
                filter.point_type.push(typeId);
            }
        }

        //if all filters enabled
        if (Object.keys(Filters.TYPES_NAMES).length === filter.point_type.length) {
            filter.point_type = [];
        }

        for (const carrierName in settings.carriers) {
            if(settings.carriers[carrierName]) {
                filter.carriers.push(carrierName);
            }
        }

        //if all filters enabled
        if (Object.keys(settings.carriers).length === filter.carriers.length) {
            filter.carriers = [];
        }

        return filter;
    }

    static getServiceDescription(point, pointFields, servicesDescription) {
        let serviceDescriptionIndex = pointFields.indexOf('serviceDescription');
        return servicesDescription[point[serviceDescriptionIndex]];
    }

    static getCarrier(point, pointFields, carriersMap) {
        let carrierIndex = pointFields.indexOf('carrier');
        return carriersMap[point[carrierIndex]];
    }

    static getTransit(point, pointFields) {

        let transitMinIndex = pointFields.indexOf('transit_min');
        let transitMaxIndex = pointFields.indexOf('transit_max');
        let strTransit = point[transitMinIndex];
        if (point[transitMaxIndex] !== null) {
            strTransit += '-' + point[transitMaxIndex];
        }
        strTransit += ' ';

        let numForDeclination = point[transitMaxIndex] || point[transitMinIndex];
        if (numForDeclination) {
            switch (numForDeclination.toString().substr(-1, 1)) {
                case '1':
                    strTransit += 'рабочий день';
                    break;

                case '2':
                case '3':
                case '4':
                    strTransit += 'рабочих дня';
                    break;

                default:
                    strTransit += 'рабочих дней';
            }
        }

        return strTransit;
    }

    // static getCurrentPointById(pointId) {
    //     var Points = this.Points;
    //     pointId = parseInt(pointId);
    //
    //     for (var i = 0; i < Points.length; i++) {
    //         if (Points[i].id !== pointId) {
    //             continue;
    //         } else {
    //             return Points[i];
    //         }
    //     }
    // }

    static getPaymentsLimits(payLimits) {
        const paymentModeKey = Object.keys(PaymentModesMapper).find(
            (key) => PaymentModesMapper[key].code === payLimits
        );

        return PaymentModesMapper[paymentModeKey]
            ? PaymentModesMapper[paymentModeKey].label
            : '';
    }
}
