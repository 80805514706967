export default {
    BANK_CARD: {
        'label': 'Банковской картой',
        'code': 4,
        'finds': [7,74]
    },
    CASH_AND_BANK_CARD: {
        'label': 'Наличными и банковской картой',
        'code': 74,
        'finds': [74]
    },
    CASH: {
        'label': 'Наличными',
        'code': 7,
        'finds': [4,74]
    }
}